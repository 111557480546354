var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "breadcrumb mb-6" },
        [
          _c("breadcrumb", {
            attrs: {
              title: "Propostas",
              actualPage: "Visualizar",
              previousPage: "propostas-seguradora",
              previousPageTitle: "Lista de propostas",
            },
          }),
        ],
        1
      ),
      _vm.proposta.Broker
        ? [
            _c(
              "div",
              { staticClass: "w-full content p-4 visualizar-proposta-tabs" },
              [
                _c(
                  "vs-tabs",
                  { attrs: { color: "warning" } },
                  [
                    _c(
                      "vs-tab",
                      {
                        attrs: { label: "Resumo da Proposta" },
                        on: {
                          click: function ($event) {
                            return _vm.loadProposta()
                          },
                        },
                      },
                      [
                        _c(
                          "vs-row",
                          [
                            _vm.proposta.ProposalTypeId == 2
                              ? _c(
                                  "vs-col",
                                  {
                                    attrs: {
                                      "vs-type": "flex",
                                      "vs-justify": "end",
                                      "vs-align": "end",
                                      "vs-w": "12",
                                    },
                                  },
                                  [
                                    _c("visualizar-alteracoes", {
                                      attrs: {
                                        changed: _vm.diffEndosso.length > 0,
                                        showDesfazer: false,
                                        objectDiff: _vm.diffEndosso,
                                        responsavel:
                                          _vm.responsavelDataUpdateEndosso,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "vs-row",
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-lg":
                                    _vm.proposta.ProposalTypeId == 2 ? 3 : 4,
                                  "vs-sm": "12",
                                },
                              },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("Nº da proposta"),
                                ]),
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { disabled: "", size: "large" },
                                  model: {
                                    value: _vm.proposta.Number,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.proposta, "Number", $$v)
                                    },
                                    expression: "proposta.Number",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.proposta.ProposalTypeId == 2
                              ? _c(
                                  "vs-col",
                                  {
                                    attrs: {
                                      "vs-lg":
                                        _vm.proposta.ProposalTypeId == 2
                                          ? 3
                                          : 4,
                                      "vs-sm": "12",
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "flex font-semibold" },
                                      [_vm._v("Nº da apólice")]
                                    ),
                                    (_vm.proposta.Policy || {}).Number
                                      ? _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            disabled: "",
                                            size: "large",
                                          },
                                          model: {
                                            value: (_vm.proposta.Policy || {})
                                              .Number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.proposta.Policy || {},
                                                "Number",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "(proposta.Policy || {}).Number",
                                          },
                                        })
                                      : _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            disabled: "",
                                            value: "Sem número",
                                            size: "large",
                                          },
                                        }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-lg":
                                    _vm.proposta.ProposalTypeId == 2 ? 3 : 4,
                                  "vs-sm": "12",
                                },
                              },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("Data de cadastro"),
                                ]),
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { disabled: "", size: "large" },
                                  model: {
                                    value: _vm.CreateDate,
                                    callback: function ($$v) {
                                      _vm.CreateDate = $$v
                                    },
                                    expression: "CreateDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-lg":
                                    _vm.proposta.ProposalTypeId == 2 ? 3 : 4,
                                  "vs-sm": "12",
                                },
                              },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("Status"),
                                ]),
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { disabled: "", size: "large" },
                                  model: {
                                    value: _vm.statusName,
                                    callback: function ($$v) {
                                      _vm.statusName = $$v
                                    },
                                    expression: "statusName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vs-row",
                          [
                            _c(
                              "vs-col",
                              { attrs: { "vs-lg": "9", "vs-sm": "12" } },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("Corretora"),
                                ]),
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { disabled: "", size: "large" },
                                  model: {
                                    value: _vm.proposta.Broker.Name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.proposta.Broker, "Name", $$v)
                                    },
                                    expression: "proposta.Broker.Name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              { attrs: { "vs-lg": "3", "vs-sm": "12" } },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("CNPJ da Corretora"),
                                ]),
                                _c("vs-input", {
                                  attrs: {
                                    disabled: "",
                                    size: "large",
                                    value: _vm._f("maskCnpj")(
                                      _vm.proposta.Broker.CpfCnpj
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vs-row",
                          [
                            _c(
                              "vs-col",
                              { attrs: { "vs-lg": "9", "vs-sm": "12" } },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("Tomador"),
                                ]),
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: {
                                    disabled: "",
                                    value: _vm.proposta.PolicyHolderBranch
                                      ? _vm.proposta.PolicyHolderBranch.Name
                                      : _vm.proposta.PolicyHolder.Name,
                                    size: "large",
                                  },
                                }),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "detalhe-tomador",
                                        params: {
                                          tomadorUniqueId:
                                            _vm.proposta.PolicyHolder.UniqueId,
                                        },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        attrs: {
                                          color: "primary",
                                          type: "flat",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-semibold" },
                                          [
                                            _vm._v(
                                              "\n                    Visualizar cadastro completo\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              { attrs: { "vs-lg": "3", "vs-sm": "12" } },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("CNPJ"),
                                ]),
                                _c("vs-input", {
                                  attrs: {
                                    disabled: "",
                                    size: "large",
                                    value: _vm._f("maskCnpj")(
                                      _vm.proposta.PolicyHolderBranch
                                        ? _vm.proposta.PolicyHolderBranch
                                            .CpfCnpj
                                        : _vm.proposta.PolicyHolder.CpfCnpj
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vs-row",
                          [
                            _c(
                              "vs-col",
                              { attrs: { "vs-lg": "9", "vs-sm": "12" } },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("Segurado"),
                                ]),
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { disabled: "", size: "large" },
                                  model: {
                                    value: _vm.proposta.Insured.Name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.proposta.Insured,
                                        "Name",
                                        $$v
                                      )
                                    },
                                    expression: "proposta.Insured.Name",
                                  },
                                }),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "detalhe-segurado",
                                        params: {
                                          seguradoUniqueId:
                                            _vm.proposta.Insured.UniqueId,
                                        },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        attrs: {
                                          color: "primary",
                                          type: "flat",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-semibold" },
                                          [
                                            _vm._v(
                                              "\n                    Visualizar cadastro completo\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              { attrs: { "vs-lg": "3", "vs-sm": "12" } },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("CNPJ"),
                                ]),
                                _c("vs-input", {
                                  attrs: {
                                    disabled: "",
                                    size: "large",
                                    value: _vm._f("maskCnpj")(
                                      _vm.proposta.Insured.CpfCnpj
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.proposta.Beneficiaries || [],
                          function (item, index) {
                            return _c(
                              "vs-row",
                              { key: index },
                              [
                                _c(
                                  "vs-col",
                                  { attrs: { "vs-lg": "6", "vs-sm": "12" } },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "flex font-semibold" },
                                      [_vm._v("Beneficiário")]
                                    ),
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: { disabled: "", size: "large" },
                                      model: {
                                        value: item.Name,
                                        callback: function ($$v) {
                                          _vm.$set(item, "Name", $$v)
                                        },
                                        expression: "item.Name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-col",
                                  { attrs: { "vs-lg": "3", "vs-sm": "12" } },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "flex font-semibold" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$utils.isValidCpf(
                                                item.CpfCnpj
                                              )
                                                ? "CPF"
                                                : "CNPJ"
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _c("vs-input", {
                                      attrs: {
                                        disabled: "",
                                        size: "large",
                                        value: _vm.$utils.maskDocument(
                                          item.CpfCnpj
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-col",
                                  { attrs: { "vs-lg": "3", "vs-sm": "12" } },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "flex font-semibold" },
                                      [_vm._v("% do seguro")]
                                    ),
                                    _c("vs-input", {
                                      attrs: {
                                        disabled: "",
                                        size: "large",
                                        value: item.PercentageAmountValue,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        _c("hr", { staticClass: "mt-10 opacity-25" }),
                        _c(
                          "vs-row",
                          [
                            _c(
                              "vs-col",
                              {
                                class: (_vm.propostaFinancialData || {})
                                  .PrincipalModality
                                  ? "w-1/6"
                                  : "w-1/5",
                              },
                              [
                                _c("p", { staticClass: "font-semibold" }, [
                                  _vm._v("Modalidade Principal"),
                                ]),
                                _vm.proposta.Modality
                                  ? _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        disabled: "",
                                        value:
                                          _vm.proposta.Modality.Name +
                                          "(" +
                                          _vm.proposta.ModalityGroup.Name +
                                          ")",
                                        size: "large",
                                      },
                                      model: {
                                        value: _vm.proposta.Modality.Name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.proposta.Modality,
                                            "Name",
                                            $$v
                                          )
                                        },
                                        expression: "proposta.Modality.Name",
                                      },
                                    })
                                  : _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        disabled: "",
                                        value:
                                          "Sem modalidade\n                ",
                                        size: "large",
                                      },
                                    }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              {
                                class: (_vm.propostaFinancialData || {})
                                  .PrincipalModality
                                  ? "w-1/6"
                                  : "w-1/5",
                              },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("Importância Segurada"),
                                ]),
                                _c("currency-input", {
                                  staticClass:
                                    "w-full vs-inputx vs-input--input large hasValue",
                                  attrs: { disabled: "true", size: "large" },
                                  model: {
                                    value: _vm.proposta.InsuredAmountValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.proposta,
                                        "InsuredAmountValue",
                                        $$v
                                      )
                                    },
                                    expression: "proposta.InsuredAmountValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              {
                                class: (_vm.propostaFinancialData || {})
                                  .PrincipalModality
                                  ? "w-1/6"
                                  : "w-1/5",
                              },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("Início da vigência"),
                                ]),
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { disabled: "", size: "large" },
                                  model: {
                                    value: _vm.StartDate,
                                    callback: function ($$v) {
                                      _vm.StartDate = $$v
                                    },
                                    expression: "StartDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              {
                                class: (_vm.propostaFinancialData || {})
                                  .PrincipalModality
                                  ? "w-1/6"
                                  : "w-1/5",
                              },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("Prazo (dias)"),
                                ]),
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { disabled: "", size: "large" },
                                  model: {
                                    value: _vm.proposta.DeadlineDays,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.proposta,
                                        "DeadlineDays",
                                        $$v
                                      )
                                    },
                                    expression: "proposta.DeadlineDays",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              {
                                class: (_vm.propostaFinancialData || {})
                                  .PrincipalModality
                                  ? "w-1/6"
                                  : "w-1/5",
                              },
                              [
                                _c("p", { staticClass: "flex font-semibold" }, [
                                  _vm._v("Fim da vigência"),
                                ]),
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { disabled: "", size: "large" },
                                  model: {
                                    value: _vm.EndDate,
                                    callback: function ($$v) {
                                      _vm.EndDate = $$v
                                    },
                                    expression: "EndDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            (_vm.propostaFinancialData || {}).PrincipalModality
                              ? _c(
                                  "vs-col",
                                  { staticClass: "w-1/6" },
                                  [
                                    _c("p", { staticClass: "font-semibold" }, [
                                      _vm._v("Prêmio"),
                                    ]),
                                    _c("currency-input", {
                                      staticClass:
                                        "w-full vs-inputx vs-input--input large hasValue",
                                      attrs: {
                                        disabled: "true",
                                        size: "large",
                                      },
                                      model: {
                                        value: (_vm.propostaFinancialData || {})
                                          .PrincipalModality,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.propostaFinancialData || {},
                                            "PrincipalModality",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "(propostaFinancialData || {}).PrincipalModality",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.proposta.ComplementaryModality &&
                        _vm.proposta.ComplementaryModality.Id > 0
                          ? _c(
                              "vs-row",
                              [
                                _c(
                                  "vs-col",
                                  {
                                    class: (_vm.propostaFinancialData || {})
                                      .PrincipalModality
                                      ? "w-1/6"
                                      : "w-1/5",
                                  },
                                  [
                                    _c("p", { staticClass: "font-semibold" }, [
                                      _vm._v("Modalidade Secundária"),
                                    ]),
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: { disabled: "", size: "large" },
                                      model: {
                                        value:
                                          _vm.proposta.ComplementaryModality
                                            .Name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.proposta.ComplementaryModality,
                                            "Name",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "proposta.ComplementaryModality.Name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-col",
                                  {
                                    class: (_vm.propostaFinancialData || {})
                                      .PrincipalModality
                                      ? "w-1/6"
                                      : "w-1/5",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "flex font-semibold tooltip-info",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Importância Segurada"),
                                        ]),
                                        _c(
                                          "vx-tooltip",
                                          {
                                            attrs: {
                                              text: "Modalidade secundária",
                                              position: "right",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons pl-2",
                                              },
                                              [_vm._v("info")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("currency-input", {
                                      staticClass:
                                        "w-full vs-inputx vs-input--input large hasValue",
                                      attrs: {
                                        disabled: "true",
                                        size: "large",
                                      },
                                      model: {
                                        value:
                                          _vm.proposta
                                            .ComplementaryInsuredAmountValue,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.proposta,
                                            "ComplementaryInsuredAmountValue",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "proposta.ComplementaryInsuredAmountValue",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-col",
                                  {
                                    class: (_vm.propostaFinancialData || {})
                                      .PrincipalModality
                                      ? "w-1/6"
                                      : "w-1/5",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "flex font-semibold tooltip-info",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Início da vigência"),
                                        ]),
                                        _c(
                                          "vx-tooltip",
                                          {
                                            attrs: {
                                              text: "Modalidade secundária",
                                              position: "right",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons pl-2",
                                              },
                                              [_vm._v("info")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: { disabled: "", size: "large" },
                                      model: {
                                        value: _vm.StartDateComplementary,
                                        callback: function ($$v) {
                                          _vm.StartDateComplementary = $$v
                                        },
                                        expression: "StartDateComplementary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-col",
                                  {
                                    class: (_vm.propostaFinancialData || {})
                                      .PrincipalModality
                                      ? "w-1/6"
                                      : "w-1/5",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "flex font-semibold tooltip-info",
                                      },
                                      [
                                        _c("span", [_vm._v("Prazo (dias)")]),
                                        _c(
                                          "vx-tooltip",
                                          {
                                            attrs: {
                                              text: "Modalidade secundária",
                                              position: "right",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons pl-2",
                                              },
                                              [_vm._v("info")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: { disabled: "", size: "large" },
                                      model: {
                                        value:
                                          _vm.proposta
                                            .ComplementaryDeadlineDays,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.proposta,
                                            "ComplementaryDeadlineDays",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "proposta.ComplementaryDeadlineDays",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-col",
                                  {
                                    class: (_vm.propostaFinancialData || {})
                                      .PrincipalModality
                                      ? "w-1/6"
                                      : "w-1/5",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "flex font-semibold tooltip-info",
                                      },
                                      [
                                        _c("span", [_vm._v("Fim da vigência")]),
                                        _c(
                                          "vx-tooltip",
                                          {
                                            attrs: {
                                              text: "Modalidade secundária",
                                              position: "right",
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons pl-2",
                                              },
                                              [_vm._v("info")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: { disabled: "", size: "large" },
                                      model: {
                                        value: _vm.EndDateComplementary,
                                        callback: function ($$v) {
                                          _vm.EndDateComplementary = $$v
                                        },
                                        expression: "EndDateComplementary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                (_vm.propostaFinancialData || {})
                                  .PrincipalModality
                                  ? _c(
                                      "vs-col",
                                      { staticClass: "w-1/6" },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "font-semibold" },
                                          [_vm._v("Prêmio")]
                                        ),
                                        _c("currency-input", {
                                          staticClass:
                                            "w-full vs-inputx vs-input--input large hasValue",
                                          attrs: {
                                            disabled: "true",
                                            size: "large",
                                          },
                                          model: {
                                            value: (
                                              _vm.propostaFinancialData || {}
                                            ).ComplementryModality,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.propostaFinancialData || {},
                                                "ComplementryModality",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "(propostaFinancialData || {}).ComplementryModality",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.proposta.ProposalCoverages &&
                        _vm.proposta.ProposalCoverages.length > 0
                          ? _vm._l(
                              _vm.proposta.ProposalCoverages,
                              function (item, indexCoverge) {
                                return _c(
                                  "vs-row",
                                  { key: `coverge-${indexCoverge}` },
                                  [
                                    _c(
                                      "vs-col",
                                      {
                                        class: (_vm.propostaFinancialData || {})
                                          .PrincipalModality
                                          ? "w-1/6"
                                          : "w-1/5",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "flex font-semibold" },
                                          [_vm._v("Coberturas Adicionais")]
                                        ),
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            disabled: "",
                                            value: item.Coverage.Name,
                                            size: "large",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "vs-col",
                                      {
                                        class: (_vm.propostaFinancialData || {})
                                          .PrincipalModality
                                          ? "w-1/6"
                                          : "w-1/5",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "font-semibold" },
                                          [_vm._v("Valor")]
                                        ),
                                        _c("currency-input", {
                                          staticClass:
                                            "w-full vs-inputx vs-input--input large hasValue",
                                          attrs: {
                                            disabled: "true",
                                            size: "large",
                                          },
                                          model: {
                                            value:
                                              _vm.proposta.InsuredAmountValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.proposta,
                                                "InsuredAmountValue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "proposta.InsuredAmountValue",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "vs-col",
                                      {
                                        class: (_vm.propostaFinancialData || {})
                                          .PrincipalModality
                                          ? "w-1/6"
                                          : "w-1/5",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "font-semibold" },
                                          [_vm._v("Início da vigência")]
                                        ),
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            disabled: "",
                                            value: _vm.$utils.dateToDDMMYYYY(
                                              item.StartDate
                                            ),
                                            size: "large",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "vs-col",
                                      {
                                        class: (_vm.propostaFinancialData || {})
                                          .PrincipalModality
                                          ? "w-1/6"
                                          : "w-1/5",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "font-semibold" },
                                          [_vm._v("Prazo (dias)")]
                                        ),
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            disabled: "",
                                            value: _vm.$utils.diffDates(
                                              item.EndDate,
                                              item.StartDate
                                            ),
                                            size: "large",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "vs-col",
                                      {
                                        class: (_vm.propostaFinancialData || {})
                                          .PrincipalModality
                                          ? "w-1/6"
                                          : "w-1/5",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "font-semibold" },
                                          [_vm._v("Fim da vigência")]
                                        ),
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            disabled: "",
                                            value: _vm.$utils.dateToDDMMYYYY(
                                              item.EndDate
                                            ),
                                            size: "large",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    (_vm.propostaFinancialData || {})
                                      .PrincipalModality
                                      ? _c(
                                          "vs-col",
                                          { staticClass: "w-1/6" },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "font-semibold" },
                                              [_vm._v("Prêmio")]
                                            ),
                                            _c("currency-input", {
                                              staticClass:
                                                "w-full vs-inputx vs-input--input large hasValue",
                                              attrs: {
                                                disabled: "true",
                                                size: "large",
                                                value: (
                                                  (
                                                    (
                                                      _vm.propostaFinancialData ||
                                                      {}
                                                    ).Coverages || []
                                                  ).find(
                                                    (x) =>
                                                      x.CoverageId == item.Id
                                                  ) || {}
                                                ).CoveragePremium,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.endosso && _vm.endosso.Id
                          ? [
                              _c("hr", { staticClass: "my-12 opacity-25" }),
                              _c("h4", { staticClass: "mt-5" }, [
                                _vm._v("Endosso"),
                              ]),
                              _c(
                                "vs-row",
                                [
                                  _c(
                                    "vs-col",
                                    { staticClass: "w-1/4" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "font-semibold" },
                                        [_vm._v("Número")]
                                      ),
                                      _c("vs-input", {
                                        staticClass: "w-full",
                                        attrs: { disabled: "", size: "large" },
                                        model: {
                                          value: _vm.endosso.Number,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.endosso, "Number", $$v)
                                          },
                                          expression: "endosso.Number",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-col",
                                    { staticClass: "w-1/4" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "font-semibold" },
                                        [_vm._v("Motivo")]
                                      ),
                                      _c("vs-input", {
                                        staticClass: "w-full",
                                        attrs: { disabled: "", size: "large" },
                                        model: {
                                          value:
                                            _vm.endosso.ReasonEndorsementRequest
                                              .Name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.endosso
                                                .ReasonEndorsementRequest,
                                              "Name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "endosso.ReasonEndorsementRequest.Name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-col",
                                    { staticClass: "w-1/2" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "font-semibold" },
                                        [_vm._v("Tipo")]
                                      ),
                                      _vm._l(
                                        _vm.endosso.ChangeTypes.map(
                                          (x) => x.Description
                                        ),
                                        function (text, index) {
                                          return _c(
                                            "span",
                                            {
                                              key: index,
                                              staticClass:
                                                "text-lg inline-flex pb-3",
                                            },
                                            [
                                              text
                                                ? _c("i", {
                                                    staticClass:
                                                      "onpoint-check-circle my-auto mr-5",
                                                  })
                                                : _vm._e(),
                                              text
                                                ? _c("p", {
                                                    domProps: {
                                                      innerHTML: _vm._s(text),
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _vm.restituitionPermition &&
                              _vm.canSetRefundEndorsement
                                ? _c(
                                    "vs-row",
                                    [
                                      _c("configuracao-restituicao", {
                                        attrs: { proposta: _vm.proposta },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _c("hr", { staticClass: "my-12 opacity-25" }),
                        _c("dados-financeiros", {
                          staticClass: "mt-10",
                          attrs: {
                            blockInputs: _vm.blockInputs,
                            compareChanges: _vm.compareChanges,
                            StringObservations: _vm.StringObservations,
                            permissoesCocorretagemMapfre:
                              _vm.permissoesCocorretagemMapfre,
                          },
                          on: {
                            saveProposal: function ($event) {
                              return _vm.atualizarProposta(true)
                            },
                          },
                        }),
                        _vm.podeExibirCosseguro
                          ? _c("CosseguroMapfre", {
                              on: {
                                tipoCosseguroMapfre:
                                  _vm.popularTipoCosseguroMapfre,
                                cosseguroMapfre: _vm.popularCosseguroMapfre,
                              },
                            })
                          : _vm._e(),
                        _vm.podeExibirResseguro
                          ? _c("ResseguroMapfre", {
                              on: {
                                tipoResseguroMapfre:
                                  _vm.popularTipoResseguroMapfre,
                                resseguroMapfre: _vm.popularResseguroMapfre,
                              },
                            })
                          : _vm._e(),
                        _vm.proposalEndorsement &&
                        _vm.proposalEndorsement.length
                          ? [
                              _c("hr", { staticClass: "my-12 opacity-25" }),
                              _c("h4", { staticClass: "mt-5" }, [
                                _vm._v("Objeto do endosso"),
                              ]),
                              _vm._l(
                                _vm.proposalEndorsement,
                                function (object, index) {
                                  return _c(
                                    "vs-row",
                                    { key: `endosso-${index}` },
                                    [
                                      _c(
                                        "vs-col",
                                        {
                                          staticClass: "quill-container",
                                          attrs: { "vs-w": "12" },
                                        },
                                        [
                                          _c("jodit-editor", {
                                            staticClass: "mt-3",
                                            attrs: { config: _vm.joditConfig },
                                            on: {
                                              input: function ($event) {
                                                return _vm.setValueContractualTerms(
                                                  $event,
                                                  object
                                                )
                                              },
                                            },
                                            model: {
                                              value: object.Value,
                                              callback: function ($$v) {
                                                _vm.$set(object, "Value", $$v)
                                              },
                                              expression: "object.Value",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ]
                          : _vm._e(),
                        (_vm.particularClauses &&
                          _vm.particularClauses.length &&
                          !_vm.changeable) ||
                        (_vm.proposalClauses && _vm.proposalClauses.length)
                          ? [
                              _c("hr", {
                                staticClass: "mt-10 px-8 opacity-25",
                              }),
                              _c("h4", { staticClass: "mt-5" }, [
                                _vm._v("Clausulas Particulares"),
                              ]),
                              !_vm.blockInputs && !_vm.changeable
                                ? _c(
                                    "vs-row",
                                    [
                                      _c(
                                        "vs-col",
                                        {
                                          attrs: {
                                            "vs-type": "flex",
                                            "vs-justify": "flex-end",
                                          },
                                        },
                                        [
                                          !_vm.blockInputs
                                            ? _c(
                                                "vs-button",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showParticularClausesDialog()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-semibold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    Adicionar / Alterar Clausulas Particulares\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._l(
                                _vm.proposalClauses,
                                function (object, index) {
                                  return _c(
                                    "vs-row",
                                    { key: `clausules-${index}` },
                                    [
                                      _c(
                                        "vs-row",
                                        { staticClass: "mb-5" },
                                        [
                                          _c("vs-switch", {
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.InsertObjets(object)
                                              },
                                            },
                                            model: {
                                              value: object.EnableToProposal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  object,
                                                  "EnableToProposal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "object.EnableToProposal",
                                            },
                                          }),
                                          _c("label", { staticClass: "ml-2" }, [
                                            _vm._v(" Habilitar na Proposta"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vs-col",
                                        {
                                          staticClass: "quill-container",
                                          attrs: { "vs-w": "12" },
                                        },
                                        [
                                          _c("p", [
                                            _c("b", [
                                              _vm._v(_vm._s(object.Label)),
                                            ]),
                                          ]),
                                          _c("jodit-editor", {
                                            staticClass: "mt-3",
                                            attrs: { config: _vm.joditConfig },
                                            on: {
                                              input: function ($event) {
                                                return _vm.setValueContractualTerms(
                                                  $event,
                                                  object
                                                )
                                              },
                                            },
                                            model: {
                                              value: object.Value,
                                              callback: function ($$v) {
                                                _vm.$set(object, "Value", $$v)
                                              },
                                              expression: "object.Value",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ]
                          : _vm._e(),
                        _c("hr", { staticClass: "mt-12 opacity-25" }),
                        _vm.proposalTerms.length > 0
                          ? _c(
                              "vs-tabs",
                              _vm._l(
                                _vm.proposalTerms,
                                function (object, index) {
                                  return _c(
                                    "vs-tab",
                                    {
                                      key: index,
                                      attrs: { label: object.Label },
                                    },
                                    [
                                      _c(
                                        "vs-row",
                                        { staticClass: "mb-5" },
                                        [
                                          _c("vs-switch", {
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.InsertObjets(object)
                                              },
                                            },
                                            model: {
                                              value: object.EnableToProposal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  object,
                                                  "EnableToProposal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "object.EnableToProposal",
                                            },
                                          }),
                                          _c("label", { staticClass: "ml-2" }, [
                                            _vm._v(" Habilitar na Proposta"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vs-col",
                                        {
                                          staticClass: "quill-container",
                                          attrs: { "vs-w": "12" },
                                        },
                                        [
                                          _c("jodit-editor", {
                                            staticClass: "mt-3",
                                            attrs: { config: _vm.joditConfig },
                                            on: {
                                              input: function ($event) {
                                                return _vm.setValueContractualTerms(
                                                  $event,
                                                  object
                                                )
                                              },
                                            },
                                            model: {
                                              value: object.Value,
                                              callback: function ($$v) {
                                                _vm.$set(object, "Value", $$v)
                                              },
                                              expression: "object.Value",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                        _c(
                          "vs-row",
                          [
                            _c(
                              "vs-col",
                              { attrs: { "vs-w": "3" } },
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.blockInputs || _vm.changeable,
                                    },
                                    model: {
                                      value:
                                        _vm.proposta.BlockChangesInProposal,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proposta,
                                          "BlockChangesInProposal",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "proposta.BlockChangesInProposal",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Bloquear alterações de valores da Proposta\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              { attrs: { "vs-w": "3" } },
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.blockInputs || _vm.changeable,
                                    },
                                    model: {
                                      value: _vm.proposta.EnableToBigRisk,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proposta,
                                          "EnableToBigRisk",
                                          $$v
                                        )
                                      },
                                      expression: "proposta.EnableToBigRisk",
                                    },
                                  },
                                  [_vm._v("Grande risco\n              ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              { attrs: { "vs-w": "3" } },
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.blockInputs || _vm.changeable,
                                    },
                                    model: {
                                      value: _vm.proposta.EnableToMonitoring,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proposta,
                                          "EnableToMonitoring",
                                          $$v
                                        )
                                      },
                                      expression: "proposta.EnableToMonitoring",
                                    },
                                  },
                                  [_vm._v("Monitoramento\n              ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              { attrs: { "vs-w": "3" } },
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.blockInputs || _vm.changeable,
                                    },
                                    model: {
                                      value: _vm.proposta.EnableToSteppin,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proposta,
                                          "EnableToSteppin",
                                          $$v
                                        )
                                      },
                                      expression: "proposta.EnableToSteppin",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Retoma de objeto (Step-in)\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("hr", { staticClass: "mt-5 px-8 opacity-25" }),
                        _c(
                          "vs-row",
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-w": "12",
                                  "vs-type": "flex",
                                  "vs-justify": "end",
                                },
                              },
                              [
                                _vm.proposta
                                  .ProposalInstallmentArchiveBilletId != null
                                  ? _c(
                                      "vs-button",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { color: "primary" },
                                        nativeOn: {
                                          click: function ($event) {
                                            _vm.parcelasModal = true
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-semibold pr-2 pl-2",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Download do(s) boleto(s)\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.enableEndorsementDownload
                                  ? _c(
                                      "vs-button",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { color: "primary" },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.getEndorsementDocument()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-semibold pr-2 pl-2",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Download Endosso\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { color: "primary" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.generateContractDraft()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "font-semibold pr-2 pl-2",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Download da minuta\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                !_vm.blockInputs
                                  ? _c(
                                      "vs-button",
                                      {
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.changeable,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.atualizarProposta()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-semibold pr-2 pl-2",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Atualizar proposta\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "vs-tab",
                      { attrs: { label: "Histórico do Motor" } },
                      [
                        _c("historico-motor", {
                          attrs: {
                            proposalLogs: _vm.proposalEngineExecutionLogs,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-tab",
                      { attrs: { label: "Follow Up" } },
                      [
                        _c("follow-up", {
                          attrs: {
                            proposalObservations: _vm.proposalObservations,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-tab",
                      { attrs: { label: "Documentos" } },
                      [
                        _c("proposal-documents", {
                          attrs: {
                            proposalDocuments: _vm.proposalDocuments,
                            proposalDeletedDocuments:
                              _vm.proposalDeletedDocuments,
                            documentType: 3,
                          },
                          on: { loadProposta: _vm.loadProposta },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "vs-popup",
        {
          attrs: { title: "Aviso", active: _vm.changeableModal },
          on: {
            "update:active": function ($event) {
              _vm.changeableModal = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vw-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("h5", [_vm._v("A proposta não pode ser alterado.")]),
            ]),
            _c("div", { staticClass: "vx-col w-full p-1" }, [
              _c("h6", [
                _vm._v(
                  "\n          Proposta emitida ou aguardando confirmação da emissão pela\n          seguradora.\n        "
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "termos-modal fix-scroll",
          attrs: {
            fullscreen: "",
            "button-close-hidden": true,
            title: "Condições Particulares",
            active: _vm.particularClausesDialog,
          },
          on: {
            "update:active": function ($event) {
              _vm.particularClausesDialog = $event
            },
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ invalid }) {
                  return [
                    _vm.particularClauses &&
                    _vm.particularClauses.length &&
                    !!_vm.particularClausesDialog
                      ? _c("particular-clauses-select", {
                          staticClass: "mb-10",
                          attrs: {
                            proposal: _vm.proposta,
                            options: _vm.particularClauses,
                          },
                          on: {
                            "proposal-terms-values-change":
                              _vm.particularClausesProposalTermsValuesChanged,
                          },
                          model: {
                            value: _vm.particularClausesSelect,
                            callback: function ($$v) {
                              _vm.particularClausesSelect = $$v
                            },
                            expression: "particularClausesSelect",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "vs-row",
                      {
                        attrs: { "vs-type": "flex", "vs-justify": "flex-end" },
                      },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "mr-3",
                            attrs: { color: "dark" },
                            on: {
                              click: function ($event) {
                                _vm.particularClausesDialog = false
                              },
                            },
                          },
                          [_vm._v("\n          Cancelar\n        ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: invalid,
                              type: "primary",
                              loading: _vm.loadingButton,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateParticularClausesInPropose()
                              },
                            },
                          },
                          [_vm._v("Prosseguir")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("listar-parcelas-popup", {
        attrs: {
          parcelasModal: _vm.parcelasModal,
          propostaUniqueId: _vm.$route.params.propostaUniqueId
            ? _vm.$route.params.propostaUniqueId
            : _vm.$route.params.proposalType,
          autoLoad: true,
        },
        on: {
          fecharParcelasModal: function ($event) {
            _vm.parcelasModal = false
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }